import React from 'react'
import '../Styles/Project.css'
import TagsComponent from '../Components/TagsComponent'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import LinkComponent from '../Components/LinkComponent'

const Project = ({ platform, project }) => {
    
    const icons = require.context('../../public/Icons', true)
    const images = require.context('../../public/Images', true)

    if(platform === "Mobile") {
        return (
            <div className="project">
                <div className="header">
                    <img src={icons('./' + project.icon)} alt="Icon" />
                </div>
                <div className="title">
                    <h1 className='big-title-text' >
                        { project.name }
                    </h1>
                </div>
                <div className="tags">
                    {
                        project.tags.map((e, idx) => <TagsComponent tag={e} key={idx} first={idx === 0} /> )
                    }
                </div>
                <div className="description">
                    <h1 className='title-text'>Description</h1>
                    <span className='text' >
                        { project.description }
                    </span>
                </div>
                <div className="story">
                    <h1 className='title-text'>Histoire du projet</h1>
                    <span className='text'>
                        { project.story }
                    </span>
                </div>
                <div className="images">
                    <Carousel
                        autoPlay={true}
                        interval={5000}
                        infiniteLoop={true}
                        showThumbs={false}
                    >
                        {
                            project.images.map((e, idx) => <img src={images('./' + e)} key={idx} alt={'image ' + (idx + 1)} />)
                        }
                    </Carousel>
                </div>
                <div className="links">
                    {
                        project.links.map((e, idx) => <LinkComponent link={e} key={idx} />)
                    }
                </div>
            </div>
        )
    } else {
        return (
            <div className="project">
                <div className='sheet'>
                    <div className="header">
                        <img src={icons('./' + project.icon)} alt="Icon" />
                    </div>
                    <div className="title">
                        <h1 className='big-title-text' >
                            { project.name }
                        </h1>
                    </div>
                    <div className="tags">
                        {
                            project.tags.map((e, idx) => <TagsComponent tag={e} key={idx} first={idx === 0} /> )
                        }
                    </div>
                    <div className="description">
                        <h1 className='title-text'>Description</h1>
                        <span className='text' >
                            { project.description }
                        </span>
                    </div>
                    <div className="story">
                        <h1 className='title-text'>Histoire du projet</h1>
                        <span className='text'>
                            { project.story }
                        </span>
                    </div>
                    <div className="links">
                        {
                            project.links.map((e, idx) => <LinkComponent link={e} key={idx} />)
                        }
                    </div>
                </div>
                <div className={"images " + (project.tags.includes("Mobile") ? "mobile" : "")}>
                    <Carousel
                        autoPlay={true}
                        interval={5000}
                        infiniteLoop={true}
                        showThumbs={false}
                    >
                        {
                            project.images.map((e, idx) => <img src={images('./' + e)} key={idx} alt={'image ' + (idx + 1)} />)
                        }
                    </Carousel>
                </div>
            </div>
        )
    }
}

export default Project