import React from 'react'
import '../Styles/LinkComponent.css'
import github from '../Assets/Icon/Github.svg'
import download from '../Assets/Icon/Download.svg'
import project from '../Assets/Icon/Project.svg'
import playStore from '../Assets/Icon/PlayStore.svg'

const LinkComponent = ({ link }) => {

    const getSrc = () => {
        switch(link.icon) {
            case "Github":
                return github
            case "Download":
                return download
            case "Project":
                return project
            case "PlayStore":
                return playStore
            default:
                throw new Error("Link Icon : " + link.icon + " doesn't exist")
                //return null
        }
    }

    return (
        <div className='link' >
            <img
                src={ getSrc() }
                alt='icon'
            />
            <a href={link.url} target='_blank' rel='noreferrer' >{link.label}</a>
        </div>
    )
}

export default LinkComponent