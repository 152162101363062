import React from 'react'
import "../Styles/DesktopNavBar.css"
import logo from '../Assets/Icon/Logo.png'

const DesktopNavBar = ({ activePage, onHomeClick, onProjectsClick, onContactClick }) => {
    return (
        <div className='desktop navbar' >
            <img src={logo} alt="Logo" />
            <div className='linkContainer' >
                <span 
                    className={'link ' + (activePage === "Home" ? 'active' : '')}
                    onClick={() => onHomeClick()}
                >
                    Accueil
                </span>
                <span 
                    className={'link ' + (activePage === "Projects" ? 'active' : '')}
                    onClick={() => onProjectsClick()}
                >
                    Projets
                </span>
                <span 
                    className={'link ' + (activePage === "Contact" ? 'active' : '')}
                    onClick={() => onContactClick()}
                >
                    Contact
                </span>
            </div>
		</div>
    )
}

export default DesktopNavBar