import React from 'react'
import "../Styles/Home.css"
import logo from "../Assets/Icon/Logo.png"
import github from '../Assets/Icon/Github.png'
import download from '../Assets/Icon/Download.png'
//import cv from '../Assets/Files/CV.pdf'

const Home = ({ platform, onCallProjectClick }) => {

    if(platform === "Mobile") {
        return (
            <div className='home content'>
                <div className='home header' >
                    <img src={logo} alt="Logo" />
                </div>
                <div className='home top-part' >
                    <h1>BIENVENUE SUR MON PORTEFOLIO</h1>
                    <span>Je suis Louise Rojon, développeuse full-stack. Sur ce site, je vous donne un aperçu de mes différents projets et de mes compétences.</span>
                </div>
                <div className='home middle-part'>
                    <h1>QUI SUIS-JE ?</h1>
                    <span>Passionnée de programmation et de jeux vidéos, j'ai fait du développement mon métier. J'aime apprendre de nouvelles technos pour mes projets, en fonction de mes besoins.</span>
                    <span>Je mets un point d'honneur à me tenir informée des nouvelles avancées de mon domaine.</span>
                </div>
                <div className='home bottom-part' >
                    <div className='home callProject'>
                        <button
                            onClick={onCallProjectClick}
                        >
                            DÉCOUVREZ MES PROJETS
                        </button>
                    </div>
                    <div className='home socialNetwork'>
                        <div className='home snGroup'>
                            <img src={github} alt="GitHub" />&nbsp;
                            <a className='link' target='about:blank' href='https://github.com/LRojon' >Mon Github</a>
                        </div>
                        <div className='home snGroup'>
                            <img src={download} alt="GitHub" />&nbsp;
                            <a className='link' target='about:blank' href='./Files/CV.pdf'>Télécharger mon CV</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='home content'>
                <div className='home top-part' >
                    <h1>BIENVENUE SUR MON PORTEFOLIO</h1>
                    <span>Je suis Louise Rojon, développeuse full-stack. Sur ce site, je vous donne un aperçu de mes différents projets et de mes compétences.</span>
                    
                    <div className='home callProject'>
                        <button
                            onClick={onCallProjectClick}
                        >
                            DÉCOUVREZ MES PROJETS
                        </button>
                    </div>
                </div>
                <div className='home middle-part'>
                    <h1>QUI SUIS-JE ?</h1>
                    <span>Passionnée de programmation et de jeux vidéos, j'ai fait du développement mon métier. J'aime apprendre de nouvelles technos pour mes projets, en fonction de mes besoins.</span>
                    <span>Je mets un point d'honneur à me tenir informée des nouvelles avancées de mon domaine.</span>
                </div>
                <div className='home bottom-part' >
                    <div className='home socialNetwork'>
                        <div className='home snGroup'>
                            <img src={github} alt="GitHub" />&nbsp;
                            <a className='link' target='about:blank' href='https://github.com/LRojon' >Mon Github</a>
                        </div>
                        <div className='home snGroup'>
                            <img src={download} alt="GitHub" />&nbsp;
                            <a className='link' target='about:blank' href="./Files/CV.pdf">Télécharger mon CV</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Home