import React, { useState } from 'react'
import "../Styles/Projects.css"
import projectIcon from '../Assets/Icon/Projects.png'
import Data from '../Assets/Classes/Data'
import CategoryComponent from '../Components/CategoryComponent'
import ThumbnailComponent from '../Components/ThumbnailComponent'

const Projects = ({ platform, onProjectClick }) => {

    const [search, setSearch] = useState("")

    Data.get_instance()

    const get_category_list = () => {
        return (
            <div className="list">
                <CategoryComponent
                    title='PROJETS MOBILES'
                    projects={Data.get_instance().find_by_category('Mobile')}
                    onProjectClick={(id) => onProjectClick(id)}
                />
                <CategoryComponent
                    title='PROJETS WEB'
                    projects={Data.get_instance().find_by_category('Web')}
                    onProjectClick={(id) => onProjectClick(id)}
                />
                <CategoryComponent
                    title='AUTRES PROJETS'
                    projects={Data.get_instance().find_by_category('Autres')}
                    onProjectClick={(id) => onProjectClick(id)}
                />
            </div>
        )
    }

    const get_projects_list = () => {
        let projectToDisplay = Data.get_instance().find(search)
        return (
            <div className='list direct'>
                {
                    projectToDisplay.map((e, idx) => <ThumbnailComponent project={e} key={idx} onThumbnailClick={(id) => onProjectClick(id)} />)
                }
            </div>
        )
    }

    return (
        <div className='projects content'>
            <div className="header">
                <img src={projectIcon} alt="Projets" />
            </div>
            <div className="top-part">
                <h1>PROJETS</h1>
                <span>
                    Vous trouverez ici une sélection de projets que j'ai réalisés. Ils reflètent la diversité de mes compétences. 
                    Ils sont aussi assez révélateurs de mes centres d'intêret, où figurent en bonne place les jeux vidéos et les jeux de rôle !
                </span>
                <br/>
                <span>
                    J'aime apprendre de nouvelles technologies pour réaliser mes projets, et je suis assez polyvalente.
                </span>
            </div>
            <div className="bottom-part">
                <div className="search">
                    <input 
                        type="text" 
                        className="searchBar"
                        placeholder='Rechercher une techno, un projet, un framework...'
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </div>
                {
                    search.trim() === "" ? get_category_list() : get_projects_list()
                }
            </div>
        </div>
    )
}

export default Projects