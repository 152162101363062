import React from 'react'
import '../Styles/ThumbnailComponent.css'

const ThumbnailComponent = ({ project, onThumbnailClick }) => {

    const images = require.context('../../public/Icons', true)

    // Old onClick : onThumbnailClick(project.id)
    return (
        <div 
            className='thumbnail'
            onClick={() => onThumbnailClick(project.id)}
        >
            <img src={images('./' + project.icon)} alt="Thumbnail" />
            <span>{project.name}</span>
        </div>
    )
}

export default ThumbnailComponent