import React from 'react'
import "../Styles/MobileNavBar.css"
import projectIcon from "../Assets/Icon/Projects.png"
import homeIcon from "../Assets/Icon/Home.png"
import contactIcon from "../Assets/Icon/Contact.png"

const MobileNavBar = ({ activePage, onHomeClick, onProjectsClick, onContactClick }) => {
    return (
        <div className='navbar' >
            <button 
                className={'button ' + (activePage === "Projects" ? 'active' : '')} 
                onClick={onProjectsClick}
            >
                <img src={projectIcon} alt="Projects" />
                <span className='label'>Projets</span>
            </button>
            <button 
                className={'button ' + (activePage === "Home" ? 'active' : '')} 
                onClick={onHomeClick}
            >
                <img src={homeIcon} alt="Accueil" />
                <span className='label'>Accueil</span>
            </button>
            <button 
                className={'button ' + (activePage === "Contact" ? 'active' : '')} 
                onClick={onContactClick}
            >
                <img src={contactIcon} alt="Contact" />
                <span className='label'>Contact</span>
            </button>
		</div>
    )
}

export default MobileNavBar