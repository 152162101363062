import Home from './Pages/Home';
import './Styles/App.css';
import { useState } from 'react';
import DesktopNavBar from './Components/DesktopNavBar';
import Projects from './Pages/Projects';
import Contact from './Pages/Contact';
import Project from './Pages/Project';
import Data from './Assets/Classes/Data';


const AppDesktop = () => {
	
	const [page, setPage] = useState('Home')
	const [projectToShow, setProjectToShow] = useState(-1)

	const getPage = () => {
		switch (page) {
			case "Home":
				return (<Home platform={"Desktop"} onCallProjectClick={() => setPage("Projects")} />)
			case "Projects":
				return(<Projects platform={"Desktop"} onProjectClick={(id) => { setProjectToShow(id); setPage('Project') }} />)
			case "Contact":
				return(<Contact platform={"Desktop"} onCallProjectClick={() => setPage("Projects")} />)
			case "Project":
				return(<Project platform={"Desktop"} project={Data.get_instance().find_by_id(projectToShow)} />)
			default:
				return (<p style={{ color: 'red' }} > Error !! </p>)
		}
	}

	return (
		<div className="App Desktop">
			<DesktopNavBar
				activePage={page}
				onHomeClick={() => {
					setPage('Home')
				}}
				onProjectsClick={() => {
					setPage('Projects')
				}}
				onContactClick={() => {
					setPage('Contact')
				}}
			/>
			<div className='content-wrapper' >
				{
					getPage()
				}
			</div>
		</div>
	);
}

export default AppDesktop;
