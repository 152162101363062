export default class ProjectData {
    id
    name
    icon
    category
    description
    story
    images
    tags
    links

    #categories = ["Mobile", "Web", "Autres"]

    /**
     * 
     * @param {Number} id 
     * @param {String} name 
     * @param {String} icon 
     * @param {String} description 
     * @param {String} story 
     * @param {Array[String]} images 
     * @param {Array[String]} tags 
     * @param {Array[Link]} links 
     */
    constructor(id, name, icon, category, description, story, images, tags, links) {
        this.id = id
        this.name = name
        this.icon = icon
        this.category = category
        this.description = description
        this.story = story
        this.images = images
        this.tags = tags
        this.links = links
    }

    get_category() {
        return this.#categories[this.category]
    }
}


export class Link {
    label
    url
    icon

    /**
     * 
     * @param {String} label 
     * @param {String} url 
     * @param {String} icon Possible Value: Github | Dowload | PlayStore | Project
     */
    constructor(label, url, icon) {
        this.label = label
        this.url = url
        this.icon = icon
    }
}