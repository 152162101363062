import './Styles/App.css';
import AppMobile from './AppMobile';
import AppDesktop from './AppDesktop';
import { useState } from 'react';

const App = () => {

	const [isMobile, setIsMobile] = useState(window.innerWidth <= 810)

	window.addEventListener('resize', () => {
		setIsMobile(window.innerWidth <= 810)
	})

	if (isMobile) {
		return(<AppMobile />)
	} else {
		return(<AppDesktop />)
	}
}

export default App;
