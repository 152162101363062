import file from '../Data/Projects.json'
import ProjectData, { Link } from './Project'

export default class Data {
    instance
    /**
     * @type Array
     */
    data

    constructor(data) {
        this.data = data
    }

    /**
     * 
     * @returns Data
     */
    static init() {
        const data = []
        for(const proj of file) {
            const links = []
            for(const link of proj.links) {
                links.push(new Link(link.label, link.url, link.icon))
            }
            data.push(new ProjectData(proj.id, proj.name, proj.icon, proj.category, proj.description, proj.story, proj.images, proj.tags, links))
        }
        return new Data(data)
    }

    static get_instance() {
        if(!this.instance) {
            this.instance = this.init()
        }
        return this.instance
    }


    find_by_id(value) {
        if(value < 0 || value >= this.data.length) {
            throw new Error('id ' + value + ' out of bond')
        }
        return this.data.filter(e => e.id === value)[0]
    }

    find_by_category(value) {
        const ret = this.data.filter(e => (e.get_category() === value || e.category === value))
        return ret
    }

    find(value) {
        value = value.trim()
        const ret = []
        for(const prj of this.data) {
            if(prj.name.includes(value)) {
                ret.push(prj)
                continue
            }
            for(const e of prj.tags) {
                if(e.toLowerCase().includes(value.toLowerCase())) {
                    ret.push(prj)
                    break
                }
            }
        }
        return ret
    }
}