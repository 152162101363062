import React, { useState } from 'react'
import '../Styles/CategoryComponent.css'
import dropdown_down from '../Assets/Icon/Dropdown_Down.png'
import dropdown_up from '../Assets/Icon/Dropdown_Up.png'
import ThumbnailComponent from './ThumbnailComponent'

const CategoryComponent = ({ title, projects, onProjectClick }) => {

	const [show, setShow] = useState(false)

    return (
        <div className="category">
			<div 
				className='header' 
				onClick={() => setShow(!show)}
			>
				<span>
					{title}
				</span>
				<img 
					src={show ? dropdown_up : dropdown_down} 
					alt="Dropdown icon"
				/>
			</div>
			<div className={'grid ' + (show ? '' : 'hide')} >
				{
					projects.map(e => <ThumbnailComponent project={e} key={e.id} onThumbnailClick={(id) => onProjectClick(id)} />)
				}
			</div>
		</div>
    )
}

export default CategoryComponent