import React from 'react'
import "../Styles/Contact.css"
import contactLogo from '../Assets/Icon/Contact.png'
import githubLogo from '../Assets/Icon/Github.png'
import dowloadLogo from '../Assets/Icon/Download.png'

const Contact = ({ platform, onCallProjectClick }) => {
    return (
        <div className='contact content'>
            <div className='contact header'>
                <img src={contactLogo} alt="Contact" />
            </div>
            <div className='contact description'>
                <h1>ME CONTACTER</h1>
                <span>Si possible, merci de privilégier le contact par mail.<br/>Merci d'avance!</span>
            </div>
            <div className='contact coordinate'>
                <span>
                    Mail: <a href="mailto:louise.rojon@gmail.com" className='bold'>louise.rojon@gmail.com</a>
                </span>
                <span>
                    Tel: <span className='bold'>06 20 23 56 61</span>
                </span>
                <a href="https://www.linkedin.com/in/louise-rojon-89a752180/" target='_blank' rel='noreferrer' >Linkedin</a>
            </div>
            <div className='contact socialNetwork'>
                <div className='contact snGroup'>
                    <img src={githubLogo} alt="Github" />&nbsp;
                    <a href="https://github.com/LRojon" className='link' target='_blank' rel='noreferrer'  >Mon Github</a>
                </div>
                <div className='contact snGroup'>
                    <img src={dowloadLogo} alt="CV" />&nbsp;
                    <a href='./Files/CV.pdf' className='link' target='_blank' rel='noreferrer'  >Télécharger mon CV</a>
                </div>
            </div>
            <div className='contact callProject'>
                <button
                    onClick={onCallProjectClick}
                >
                    DÉCOUVREZ MES PROJETS
                </button>
            </div>
            <div style={{ flex: 2 }}></div>
        </div>
    )
}

export default Contact