import React from 'react'
import '../Styles/TagsComponent.css'

const TagsComponent = ({ tag, first }) => {
    return (
        <div
            className={'tag ' + (first ? 'first' : '')}
        >
            {tag}
        </div>
    )
}

export default TagsComponent